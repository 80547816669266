import React, { Fragment, useState } from 'react';

// You'll need some polyfilled fetching library, 
// if thats your jam.  In addition, something to easily
// grab cookies from the browser. 

import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import { Button, Form, Row, Col } from 'react-bootstrap';

function ContactForm() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [message, setMessage] = useState('');
    const formSuccessMessage = 'Gracias.';
    const formErrorMessage = 'Error. Por favor hable a Vazquez Consultores directament por telefono.';
    const formId = "a8929da0-5cdb-4378-b0c4-948c1f410db0";
    const portalId = "20526468";


    const submitForm = e => {
        if (e) e.preventDefault();

        // In Gatsby, we're building our static site in a Node
        // environment, rather than a browser environment, so anything
        // browser related needs to be wrapped in some version of the
        // following isBrowser variable. 

        // What we're getting here is some contextual data to send 
        // along to Hubspot so it can organize and track forms as 
        // they relate to specific users in the CRM.  The Hubspot 
        // plugin we installed earlier provides this hutk value as a cookie.
        // pageName & pageUri should be pretty self explanatory. 

        const isBrowser = typeof window !== 'undefined';
        const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
        const pageUri = isBrowser ? window.location.href : null;
        const pageName = isBrowser ? document.title : null;
        const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

        setLoading(true);

        // This object shape is what's required in the Hubspot API 
        // documentation.  There's an additional legalConsentOptions object 
        // I've left out here, but would be important if you're working on a 
        // legitimate organization's site & they want to be safe from any sort 
        // of GDPR guff. 
        //
        // Read more here:
        // https://developers.hubspot.com/docs/methods/forms/submit_form_v3

        const body = {
            submittedAt: Date.now(),
            fields: [
                {
                    name: 'firstname',
                    value: firstname,
                },
                {
                    name: 'lastname',
                    value: lastname,
                },
                {
                    name: 'email',
                    value: email,
                },
                {
                    name: 'phone',
                    value: phone,
                },
                {
                    name: 'message',
                    value: message,
                },
            ],
            context: {
                hutk,
                pageUri,
                pageName,
            },
        };

        // These specific headers are required for whatever reason,
        // so don't forget to include them. 

        fetch(postUrl, {
            method: 'post',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json, application/xml, text/plain, text/html, *.*',
            }),
        })
            .then(res => res.json())
            .then(() => {
                setSuccess(true);
                setError(false);
                setLoading(false);
                setFirstname('');
                setLastname('');
                setEmail('');
                setPhone('');
                setMessage('');
            })
            .catch(err => {
                setSuccess(false);
                setError(err);
                setLoading(false);
            });
    };

    // Finally, the data-form-id and data-portal-id attributes 
    // don't actually do anything for us in terms of getting our 
    // data from A to B.  However, if these aren't included on the 
    // form element, Hubspot gets a little confused when labelling 
    // submissions in the dashboard, so be sure to include these attrs 
    // if you don't want your submission names looking like styled-component
    // class names a la "New Submission from Contact__Form__xydhf2_kskl"

    return (
        <div class="container my-3" id="form">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <span class="wow fadeInDown" data-wow-delay=".2s">Contacto</span>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">Contacto</h2>
                        <p class="wow fadeInUp" data-wow-delay=".6s">Llámanos al <a href="tel:+19565160236" style={{ color: '#473bf0' }}>(956) 516 0236</a> ó llena el formulario <br />para más información y presupuestos personalizados.</p>
                        <h3 class="overlay-text">Contacto</h3>
                    </div>
                </div>
            </div>
            <Fragment>
                <Form
                    data-form-id={formId}
                    data-portal-id={portalId}
                    disabled={loading}
                    onSubmit={submitForm}
                >
                    {success && (
                        // <Success>
                        <p>{formSuccessMessage}</p>
                        // </Success>
                    )}
                    {!success && (
                        <Fragment>
                            <Row className="g-2 mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label htmlFor="contact-first-name">NOMBRE</Form.Label>
                                    <Form.Control
                                        id="contact-first-name"
                                        type="text"
                                        value={firstname}
                                        onChange={e => setFirstname(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback>Listo!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label htmlFor="contact-last-name">APELLIDO</Form.Label>
                                    <Form.Control
                                        id="contact-last-name"
                                        type="text"
                                        value={lastname}
                                        onChange={e => setLastname(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Listo!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">CORREO ELECTRÓNICO</Form.Label>
                                <Form.Control
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    // placeholder="Correo electrónico"
                                    required
                                />
                                <Form.Control.Feedback tooltip type="invalid">
                                    Correo Electronico malo
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="tel">NÚMERO DE TELÉFONO</Form.Label>
                                <Form.Control
                                    id="tel"
                                    type="tel"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <Form.Control.Feedback tooltip type="invalid">
                                    Gracias
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label htmlFor="contact-message">MENSAJE</Form.Label>
                                <Form.Control
                                    as="textarea" rows={6}
                                    id="contact-message"
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    required />
                            </Form.Group>

                            {error && (
                                // <ErrorBox>
                                <p>{formErrorMessage}</p>
                                // </ErrorBox>
                            )}

                            <div class="button">
                                <Button type="submit">Enviar</Button>
                            </div>

                        </Fragment>
                    )}
                </Form>
            </Fragment>
        </div>
    );
}

// const FormContact = () => {
//     return (
//         <div class="container my-3" id="form">

//             <div class="row">
//                 <div class="col-12">
//                     <div class="team-contact-form">
//                         <form action="#" method="POST">
//                             <div class="row">
//                                 <div class="col-lg-6 col-12">
//                                     <div class="form-group">
//                                         <label for="fullname">NOMBRE</label>
//                                         <input type="text" name="#" class="form-control form-control-custom" id="fullname" placeholder="Nombre" />
//                                     </div>
//                                 </div>
//                                 <div class="col-lg-6 col-12">
//                                     <div class="form-group">
//                                         <label for="email">CORREO ELECTRÓNICO</label>
//                                         <input type="email" name="#" class="form-control form-control-custom" id="email" placeholder="Correo electrónico" />
//                                     </div>
//                                 </div>
//                                 <div class="col-12">
//                                     <div class="form-group">
//                                         <label for="message">MENSAJE</label>
//                                         <textarea name="#" rows="6" class="form-control form-control-custom" id="message" placeholder="Mensaje"></textarea>
//                                     </div>
//                                 </div>
//                                 <div class="col-12">
//                                     <div class="button">
//                                         <button type="submit" class="btn white-bg">Enviar</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }

export default ContactForm;
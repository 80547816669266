import React from "react";

const Hero = () => {
    return (
        <section class="hero-area" >
            <div class="hero-inner" >
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="hero-text">
                                {/* <h5 class="wow fadeInUp" data-wow-delay=".3s">ENFOCAMOS EQUIPOS A SU RENDIMIENTO ÓPTIMO</h5> */}
                                <h1 class="wow fadeInUp" data-wow-delay=".5s">Contamos con el talento humano que tu empresa necesita.
                                </h1>
                                <p class="wow fadeInUp" data-wow-delay=".7s">Ofrecemos servicios de reclutamiento, evaluación, <br />desarrollo humano y capacitación de personal.</p>
                                <div class="button wow fadeInUp" data-wow-delay=".9s">
                                    <a href="#info" class="btn">Aprende más</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="hero-image">
                                <img src="/vazquez-consultores-logo-desarollo-humano.svg" alt="Logotipo de Vazquez Consultores con subtitulo desarollo humano" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
}

export default Hero;
import React from "react";

const Footer = () => {
    return (
        <footer class="footer">
            <div class="call-action">
                <div class="container">
                    <div class="inner-content">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-8 col-12">
                                <h2>¿Dudas o Preguntas?</h2>
                                <p>Envía tu presupuesto y a la brevedad te ayudaremos a resolver dudas acerca de tu proyecto.</p>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="button">
                                    <a href="#form" class="btn">Cotiza tu proyecto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-middle">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">

                            <div class="f-about single-footer">
                                <div class="logo">
                                    <h1 class="h1 text-white">VAZQUEZ CONSULTORES</h1>

                                    {/* <a href="index.html"><img src="assets/images/logo/footer-logo.png" alt="Logo" /></a> */}
                                </div>
                                <p>
                                    Vazquez Consultores siempre contigo.<br />
                                </p>
                                {/* <div class="footer-social">
                                    <ul>
                                        <li><a href="#"><i class="lni lni-facebook-original"></i></a></li>
                                        <li><a href="#"><i class="lni lni-twitter-original"></i></a></li>
                                        <li><a href="#"><i class="lni lni-linkedin-original"></i></a></li>
                                        <li><a href="#"><i class="lni lni-google"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>

                        </div>
                        <div class="col-lg-8 col-md-8 col-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-12">

                                    {/* <div class="single-footer sm-custom-border f-link">
                                        <h3>Pages</h3>
                                        <ul>
                                            <li><a href="#">About Us</a></li>
                                            <li><a href="#">Our Services</a></li>
                                            <li><a href="#">Contact Us</a></li>
                                            <li><a href="#">Our Blog</a></li>
                                        </ul>
                                    </div> */}

                                </div>
                                <div class="col-lg-3 col-md-6 col-12">

                                    {/* <div class="single-footer sm-custom-border f-link">
                                        <h3>Product</h3>
                                        <ul>
                                            <li><a href="#">Features</a></li>
                                            <li><a href="#">Pricing</a></li>
                                            <li><a href="#">News</a></li>
                                            <li><a href="#">Support</a></li>
                                        </ul>
                                    </div> */}

                                </div>
                                <div class="col-lg-3 col-md-6 col-12">

                                    {/* <div class="single-footer md-custom-border sm-custom-border f-link">
                                        <h3>Services</h3>
                                        <ul>
                                            <li><a href="#">Digital Marketing</a></li>
                                            <li><a href="#">Content Writing</a></li>
                                            <li><a href="#">SEO for Business</a></li>
                                            <li><a href="#">UI Design</a></li>
                                        </ul>
                                    </div> */}

                                </div>
                                <div class="col-lg-3 col-md-6 col-12">

                                    {/* <div class="single-footer md-custom-border sm-custom-border f-link">
                                        <h3>Legal</h3>
                                        <ul>
                                            <li><a href="#">Privacy Policy</a></li>
                                            <li><a href="#">Terms &amp; Conditions</a></li>
                                            <li><a href="#">Support Team</a></li>
                                            <li><a href="#">Return Policy</a></li>
                                        </ul>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div class="footer-bottom">
                <div class="container">
                    <div class="inner">
                        <div class="row">
                            <div class="col-12">
                                <div class="left">
                                    <p>Designed and Developed by<a href="https://graygrids.com/" rel="nofollow" target="_blank">GrayGrids</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </footer>
    );
}

export default Footer;
import React from "react";

const Services = () => {
    return (
        // < !--Start Services Area-- >
        <section class="services section" id="services">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <span class="wow fadeInDown" data-wow-delay=".2s">Modulos de Servicios </span>
                            <h2 class="wow fadeInUp" data-wow-delay=".4s">Servicios que ofrecemos</h2>
                            <p class="wow fadeInUp" data-wow-delay=".6s">Los servicios son estructurados en base a módulos, los cuáles se pueden adecuar a los requisitos específicos de su empresa y contratarse individualmente o en combinación, de acuerdo a sus necesidades.</p>
                            <h3 class="overlay-text">Servicios</h3>
                        </div>
                    </div>
                </div>
                <div class="single-head">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-service wow fadeInUp" data-wow-delay=".2s">
                                <h3><a href="/">Módulo Uno</a></h3>
                                <div class="icon">
                                    <i class="lni lni-microscope"></i>
                                </div>
                                <p>Búsqueda, evaluación, selección y contratación de personal</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-service wow fadeInUp" data-wow-delay=".4s">
                                <h3><a href="/">Módulo Dos</a></h3>
                                <div class="icon">
                                    <i class="lni lni-blackboard"></i>
                                </div>
                                <p>Clima Laboral, satisfacción de clientes y de calidad en el servicio</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-service wow fadeInUp" data-wow-delay=".6s">
                                <h3><a href="/">Módulo Tres</a></h3>
                                <div class="icon">
                                    <i class="lni lni-ux"></i>
                                </div>
                                <p>Adecuamiento del personal a sus puestos y su desarrollo profesional</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-service wow fadeInUp" data-wow-delay=".8s">
                                <h3><a href="/">Módulo Cuatro</a></h3>
                                <div class="icon">
                                    <i class="lni lni-graph"></i>
                                </div>
                                <p>Capacitación utilizando técnicas de aprendizaje acelerado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <!--End Services Area-- >
    );
}

export default Services;
import React from "react";

const Header = () => {
    return (
        <header class="header">
            <div class="header-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <nav class="navbar fixed-top navbar-expand-lg">
                                <a class="navbar-brand" href="/">
                                    <img src="/vazquez-consultores-logo.svg"
                                        width="30" height="30" class="d-inline-block align-top"
                                        alt="Vazquez Consultores Logo" />
                                </a>
                                <a href="/">
                                    <h1 class="navbar-brand mb-0 h1">VAZQUEZ CONSULTORES</h1>
                                </a>


                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <i class="lni lni-menu open"></i>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link active" href="/">Pagina Principal</a>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link" href="#contact">Contacto</a>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link" href="#services">Servicios</a>
                                        </li>

                                        {/* <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Pages
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><a class="dropdown-item" href="about-us.html">About Us</a></li>
                                            </ul>
                                        </li> */}
                                        {/* <li class="nav-item">
                                            <a class="nav-link" href="contact.html">Contacts</a>
                                        </li> */}
                                    </ul>
                                    <div class="button">
                                        <a href="#form" class="btn">¡Contáctame!</a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
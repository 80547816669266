import * as React from "react"
import { Component } from "react";
import WOW from "wowjs";
import Header from "../components/header";
// import Achievements from "../components/achievements";
import Services from "../components/services";
import Resume from "../components/resume";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Form from "../components/form";


class IndexPage extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <>
        <main>
          <title>Home Page</title>
          <Header />
          <Hero />
          <Resume />
          <Form />
          {/* <Achievements /> */}
          <Services />
        </main>
        <Footer />
      </>
    )
  }
}

export default IndexPage

import React from "react";
import { Button } from "react-bootstrap";

const Resume = () => {
    return (
        <section class="team-details" id="contact">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <img class="img-fluid rounded mx-auto d-block" src="/gerardo-vazquez.jpg" alt="Foto de Gerardo Vazquez" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="content">
                            <span class="position">Consultor en Desarrollo Humano</span>
                            <h3 class="name">Gerardo Vázquez Costilla</h3>
                            <p class="des">31 años de experiencia en el reclutamiento, evaluación y capacitación de personal, en el giro logístico..</p>
                            <ul class="personal-info">
                                <li><span>Teléfono : </span> <a href="tel:+19565160236">(956) 516 0236</a></li>
                                <li><span>Email : </span> <a href="mailto:gerardo@vazquezconsultores.com.mx">gerardo@vazquezconsultores.com.mx</a></li>
                            </ul>

                            {/* <ul class="social">
                                <li><a href="#"><i class="lni lni-facebook-original"></i></a></li>
                                <li><a href="#"><i class="lni lni-twitter-original"></i></a></li>
                                <li><a href="#"><i class="lni lni-behance-original"></i></a></li>
                                <li><a href="#"><i class="lni lni-youtube"></i></a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div class="row" id="info">
                    <div class="col-12">
                        <div class="details-info">
                            <h3 class="heading">Experiencia Personal</h3>
                            {/* <p>
                                Lo que más te gusta de tu trabajo es ayudar a la gente en su desarrollo profesional.
                            </p>
                            <p>
                                Vi que disfrutaba mucho el entrevistar y capacitar al personal de ventas de mi primer trabajo, lo que me orillo a prepararme con capacitaciones personalizadas en temas como psicometría, grafología y ventas.
                            </p>
                            <p>
                                Siento una gran satisfacción personal y laboral, al recibir el agradecimiento y reconocimiento de la gente que he ayudado. Ya sea al contratarlo, capacitarlo, promoverlo y tratándolo de una forma profesional.
                            </p> */}
                            <a class="button" href="/CV.pdf" download>
                                <Button>Descarga CV</Button>
                            </a>

                            <h3 class="heading">Desempeño Laboral</h3>
                            {/* <p>
                                Te ayudamos a ahorrar dinero, tiempo y damos certeza del comportamiento y desempeño.
                            </p>
                            <p>
                                Conoce las fortalezas, habilidades, áreas de oportunidad, actitudes, liderazgo y competencias de tu personal.
                            </p>
                            <p>
                                Lograremos que tu clima laboral y satisfacción de tus clientes este por encima del 90%.
                            </p> */}
                            <a class="button" href="https://drive.google.com/file/d/1zoerbuH_sK8z-5KeAObL75DLYz3LE7-M/view" target="_blank" rel="noopener noreferrer">
                                <Button>Presentacion de Servicios</Button>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Resume;